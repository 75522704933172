import moment from 'moment-timezone';
export class Live {
  constructor({
    id,
    newLive,
    newLiveID,
    platform_id = null,
    liveable_id = null,
    liveable_type = '',
    input_type = 'RTMP_PUSH',
    encoding_profile = 'HD-1080p',
    cloud_formation_stack_status = '',
    cloud_formation_stack_name = '',
    media_live_channel_state = '',
    hls_url = '',
    dash_url = '',
    start_at = null,
    end_at = null,
    created_at = null,
    updated_at = null,
    deleted_at = null,
    in_progress_stack_status = null,
    completed_stack_status = null,
    not_completed_stack_status = null,
    liveable = {
      pri_pull_url: '',
      pri_pull_user: '',
      pri_pull_pass: '',
    },
  }) {
    this.platform_id = platform_id;
    this.liveable_id = liveable_id;
    this.liveable_type = liveable_type;
    this.input_type = input_type;
    this.encoding_profile = encoding_profile;
    this.cloud_formation_stack_status = cloud_formation_stack_status;
    this.cloud_formation_stack_name = cloud_formation_stack_name;
    this.media_live_channel_state = media_live_channel_state;
    this.hls_url = hls_url;
    this.dash_url = dash_url;
    this.start_at = start_at;
    this.end_at = end_at;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.deleted_at = deleted_at;
    this.in_progress_stack_status = in_progress_stack_status;
    this.completed_stack_status = completed_stack_status;
    this.not_completed_stack_status = not_completed_stack_status;
    this.liveable = liveable;

    if (typeof id !== 'number' && typeof newLiveID !== 'number') {
      this.newLive = true;
      this.newLiveID = parseInt(`${10000 - Math.floor(Math.random() * 500)}`);
    } else if (typeof newLiveID === 'number') {
      this.newLiveID = newLiveID;
      this.newLive = newLive;
    } else {
      this.id = id;
    }
  }
}

export default Live;
