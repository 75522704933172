import { cloneDeep } from 'lodash';
import { ItemTransformer } from './ItemTransformer';
import { isEqual } from 'lodash';
import DataFromMultiTypeAccessor from '@/utils/DataFromMultiTypeAccessor';
import utils from '@/utils/utils';

export class BlockTransformer {
  _block;
  _references;
  _newBlock;


  constructor(block, references, newBlock = false) {
    this._block = cloneDeep(block);
    this._references = references;
    this._newBlock = newBlock;
  }
  transform() {
    if (this._block.type === 'row' && this._block.picture_default) {
      this._block.type =
        this._block.picture_default === 'posters'
          ? 'row-posters'
          : 'row-thumbnails';
    }
    const currentBlockReference = this._references.all.blocks.find(
      (blockReference) => blockReference.name === this._block.type
    );
    if (currentBlockReference) {
      this._block.block_type_id = currentBlockReference.id;
    }
    if (
      this._block?.background_color?.id ||
      this._block?.background_color?.hex
    ) {
      if (this._block.background_color?.id === 'custom_hex'
        && this._block.background_color?.hex?.length > 0
      ) {
        this._block.background_color = this._block.background_color.hex;
      } else {
        this._block.background_color = this._block.background_color.id;
      }
    } else {
      delete this._block.background_color;
    }
    if (typeof this._block.carousel === 'object') {
      for (let device in this._block.carousel.types) {
        if (this._block.carousel.types[device] === 'miniatures') {
          this._block.carousel.types[device] = 2;
        } else if (this._block.carousel.types[device] === 'classic') {
          this._block.carousel.types[device] = 1;
        }
      }
    }
    if (typeof this._block.template === 'object') {
      if (this._block.template) {
        if (this._block.template.id === 1) {
          this._block.picture_default = 'poster';
        } else {
          this._block.picture_default = 'thumbnail';
        }
      }
      delete this._block.template;
    } else {
      delete this._block.picture_default;
    }
    if (
      this._block.type === 'banner' &&
      this._block?.items?.length > 0
    ) {
      const currentContentBanner = this._block.items[0];
      let currentContentPictureUrl =
        DataFromMultiTypeAccessor.getItemImage(currentContentBanner).url;

      // Permet de ne pas surcharger l'image de l'item associé du bloc banner
      if (this._block?.picture?.url?.length > 0) {
        if (isEqual(this._block.picture.url, currentContentPictureUrl)) {
          delete this._block.picture;
        }
      } else if (this._block?.picture.length === 0) {
        delete this._block.picture;
      }
    }

    [
      { fieldName: 'cta_label', maxlength: 250 },
      { fieldName: 'cta_url', maxlength: 500 },
      { fieldName: 'title', maxlength: 250 }
    ].forEach((fieldDefinition) => {
      if (
        this._block.type !== 'banner' &&
        this._block?.[fieldDefinition.fieldName]?.length >
        fieldDefinition.maxlength
      ) {
        this._block[fieldDefinition.fieldName] = utils.shorten(
          this._block[fieldDefinition.fieldName],
          fieldDefinition.maxlength
        );
      }
    });

    this._block.cta_target = 'self';
    if (this._block?.previewComponent) {
      delete this._block.previewComponent;
    }
    if (this._block.type === 'row-playbacks') {
      this._block.items = []
    }
    if (
      this._newBlock &&
      this._block?.items?.length > 0
    ) {
      this._block.items = this._block.items.map((item, index) => {
        const itemTransformer = new ItemTransformer(item, this._references, this._block.type);
        const rank = index + 1
        return itemTransformer.transform(rank);
      });
    }

    this._block.active = !!this._block.active

    if (this._block.picture && typeof this._block.picture === 'object') {
      for (const [key, value] of Object.entries(this._block.picture)) {
        if (value.length > 0 && value[0].id === null && value[0].key) {
          this._block.picture[key][0].url = null;
        }
      }
    }
    

    if (this._block.type === 'video' && this._block?.carousel) {
      const carouselSettings = { ...this._block.carousel };
      delete carouselSettings?.types;
      delete carouselSettings?.aspect;
      delete carouselSettings?.text_aspect;
      delete carouselSettings?.color;
      delete carouselSettings?.text_background;
      delete carouselSettings?.carousel_type_id;
      delete carouselSettings?.scroll_type;
      delete carouselSettings?.scroll_pagination;
      delete carouselSettings?.scroll_color;
      delete carouselSettings?.scroll_background_color;
      delete carouselSettings?.scroll_radius;
      this._block.carousel = undefined;
      this._block.block_video = carouselSettings;
    }

    if (this._block._experimental) {
      delete this._block._experimental;
    }

    return this._block;
  }
}
