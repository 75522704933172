import { GenericMediaService, programService, videoService, filter } from '@/services';
import { forOwn } from 'lodash';
import { prepareProgramVideo } from '@/utils/VideoUtil';
import state from './state';

const subtitleService = new GenericMediaService('subtitles');

const actions = {
  getAll({ commit }, params) {
    commit('setLoading', true);
    const dontSaveData = params?.dontSaveData;
    delete params.dontSaveData;
    if (params?.selectedFacets && params.selectedFacets.length > 0) {
      params.group_environment = params.selectedFacets
        .filter(facet => facet.facet_id === 'group_environment')
        .map(facet => facet.id).join(',');
      params.type = params.selectedFacets
        .filter(facet => facet.facet_id === 'type')
        .map(facet => facet.id).join(',');
      delete params.selectedFacets;
    }

    let queryString = filter(params).join('&');
    return videoService.getAll(queryString).then(
      (videos) => {
        if (!dontSaveData) {
          commit('getAllSuccess', videos);
        }
        commit('setLoading', false);
        return Promise.resolve(videos);
      },
      (error) => {
        
        commit('getAllFailure', error);
        commit('setLoading', false);
        return Promise.reject();
      }
    );
  },
  get({ commit, dispatch }, id) {
    commit('setLoading', true);
    return videoService.get(id).then(
      (video) => {
        commit('getSuccess', video);
        commit('setLoading', false);
        return Promise.resolve(video);
      },
      ({ error }) => {
        commit('getFailure', error);
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  getTimecodes({ commit, dispatch }, { id, init }) {
    commit('setLoading', true);
    return videoService.getTimecodes(id).then(
      (timecodes) => {
        commit('getTimecodesSuccess', { id, timecodes, init });
        return Promise.resolve(timecodes);
      },
      ({ error }) => {
        commit('getTimecodesFailure', error);
        return Promise.reject(error);
      }
    );
  },
  createTimecode({ commit, dispatch }, { videoId, timecode }) {
    commit('setLoading', true);
    const all_episodes = timecode.all_episodes;
    return videoService.createTimecode(videoId, timecode).then(
      (tc) => {
        if (all_episodes) {
          //TODO : create endpoint or attribute to update timecode of every episodes of a serie/season
        }
        commit('createTimecodeSuccess', tc);

        return Promise.resolve(tc);
      },
      ({ error }) => {
        commit('getTimecodeFailure', error);
        return Promise.reject(error);
      }
    );
  },
  updateTimecode({ commit, dispatch }, { id, videoId, timecode }) {
    commit('setLoading', true);
    const all_episodes = timecode.all_episodes;
    return videoService.updateTimecode(id, videoId, timecode).then(
      (tc) => {
        if (all_episodes) {
          //TODO : create endpoint or attribute to update timecode of every episodes of a serie/season
        }
        commit('updateTimecodeSuccess', tc);
        return Promise.resolve(tc);
      },
      ({ error }) => {
        const alert = {
          id: 'timecode-update-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.update.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  deleteTimecode({ commit, dispatch }, id) {
    commit('setLoading', true);
    return videoService.deleteTimecode(id).then(
      (timecodes) => {
        commit('deleteTimecodeSuccess', timecodes);
        return Promise.resolve();
      },
      ({ error }) => {
        commit('deleteTimecodeFailure', error);
        const alert = {
          id: 'timecode-delete-failure',
          icon: 'close',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  update({ commit, dispatch }) {
    commit('setLoading', true);
    commit('formatCurrentVideo');
    const promises = [];
  
    ['audios', 'subtitles'].forEach((assetType) => {
      let service = new GenericMediaService(assetType);
      const deltaAsset = state.assetsDelta[assetType];
  
      deltaAsset.created.forEach((asset) => {
        promises.push(service.create(asset).then(
          (success) => {
            commit('assetCreated', { assetType: assetType, assetTempId: asset.id, asset: success });
            return Promise.resolve(success);
          },
          (error) => {
            return Promise.reject(error);
          }
        ));
      });

      deltaAsset.deleted.forEach((assetId) => {
        promises.push(service.delete(assetId).then(
          (success) => {
            commit('assetDeleted', { assetType: assetType, assetId: assetId });
            return Promise.resolve(success);
          },
          (error) => {
            return Promise.reject(error);
          }
        ));
      });
    });

    promises.push(
      videoService.update(state.currentNewVideo.id, state.formattedVideo)
    );

    return Promise.all(promises).then(
      (videoUpdate) => {
        dispatch('get', state.currentNewVideo.id);
        const alert = {
          id: 'video-update-success',
          icon: 'check',
          type: 'valid',
          message: 'notifications.update.success',
        };
        dispatch('displayAlert', alert, {
          root: true,
        });
        return Promise.resolve();
      },
      (err) => {
        const alert = {
          id: 'video-update-failure',
          icon: 'error-circle',
          type: 'error',
          message: 'notifications.update.error',
        };
        console.log(err)
        dispatch('displayAlert', alert, {
          root: true,
        });
        commit('cleanVideoStates');
        commit('setLoading', false);
        return Promise.reject(err);
      }
    );
  },
  checkVideo({ commit, dispatch }, video) {
    return videoService.validateVideo(video).then(
      (video) => {
        return Promise.resolve(video);
      },
      (error) => {
        commit('setLoading', false);
        /*const alert = {
          id: 'video-exists-failure',
          icon: 'error-circle',
          type: 'error',
          message: 'error.generic',
        };
        dispatch('displayAlert', alert, { root: true });

         */
        return Promise.reject(error);
      }
    );
  },
  deleteVideo({ commit, dispatch }, payload) {
    commit('setLoading', true);
    return videoService.deleteVideo(payload).then(
      (video) => {
        commit('deleteSuccess', payload.id);
        commit('setLoading', false);
        const alert = {
          id: 'video-delete-success',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(video);
      },
      (error) => {
        commit('setLoading', false);
        const alert = {
          id: 'video-delete-failure',
          icon: 'error-circle',
          type: 'error',
          message: 'notifications.delete.error',
        };
        if (! payload?.delete_streams)    
          dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  unlinkVideoPrograms({ commit, dispatch }, { programs = [], videoId }) {
    commit('setLoading', true);
    const promises = [];
    programs.forEach((program) => {
      const { id, title, program_type_id, video } = program;
      const formattedProgram = {
        id: id,
        title: title,
        program_type_id: program_type_id,
        video: prepareProgramVideo(video, videoId),
      };
      promises.push(programService.update(formattedProgram, id));
    });

    return Promise.all(promises).then(
      (programsRes) => {
        programsRes.forEach((programRes) => {
          commit('unlinkSuccess', programRes);
        });
        commit('setLoading', false);
        const alert = {
          id: 'video-unlink-success',
          icon: 'check',
          type: 'valid',
          message: 'notifications.delete.success',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.resolve(programsRes);
      },
      (error) => {
        commit('setLoading', false);
        const alert = {
          id: 'video-unlink-failure',
          icon: 'error-circle',
          type: 'error',
          message: 'notifications.delete.error',
        };
        dispatch('displayAlert', alert, { root: true });
        return Promise.reject(error);
      }
    );
  },
  resetCurrent({ commit }) {
    commit('resetCurrent');
  },
  resetTimecodes({ commit }) {
    commit('resetTimecodes');
  },
  cleanVideoStates({ commit }) {
    commit('cleanVideoStates');
  },
};

export default actions;
