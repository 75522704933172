/**
 * Should be ordered by alphabetical order
 *
 * description: '' -> description of the feature
 * default: true -> feature is enabled by default
 * depreacted: true -> feature is deprecated and should be removed
 */
const featuresConfig = [
  {
    id: 'apply_prepaid_code_users',
    label: 'Appliquer un code prépayé aux utilisateurs',
    description: 'Activer l’application de code prépayé aux utilisateurs',
    default: false,
  },
  {
    id: 'attachment',
    label: '',
    description:
      'Ajouter des fichiers à télécharger sur vos fiches programmes.',
    default: false,
  },
  {
    id: 'avod',
    label: 'AVOD',
    description: 'Possibilité pour les utilisateurs de regarder des publicités',
    default: false,
  },
  {
    id: 'bitmovin_analytics',
    label: '',
    description: '',
    default: false,
  },
  {
    id: 'block_code',
    label: '',
    description: 'Permet d’ajouter un bloc code dans l’Animation.',
    default: false,
  },
  {
    id: 'block_faq',
    label: '',
    description: 'Permet d’ajouter un bloc FAQ dans l’Animation.',
    default: false,
  },
  {
    id: 'block_form',
    label: '',
    description: 'Permet d’ajouter un bloc formulaire dans l’Animation.',
    default: false,
  },
  {
    id: 'block_giftcard',
    label: '',
    description: 'Permet d’ajouter un bloc carte cadeau dans l’Animation.',
    default: false,
  },
  {
    id: 'block_newsletter',
    label: '',
    description: 'Permet d’ajouter un bloc newsletter dans l’Animation.',
    default: false,
  },
  {
    id: 'bo_jimo',
    label: '',
    description: 'Activer la fonctionnalité Jimo dans le BO',
    default: false,
    deprecated: true,
  },
  {
    id: 'bo_zendesk',
    label: '',
    description: 'Activer le widget Zendesk dans le BO',
    default: false,
  },
  {
    id: 'business_rules',
    label: '',
    description: 'Activer les règles métiers pour créer des blocs dynamiques',
    default: false,
  },
  {
    id: 'collection',
    label: 'Collections',
    description: 'Paramétrer et créer des fiches collection',
    default: false,
  },
  {
    id: 'css_extra',
    label: '',
    description: 'Activer la feuille de style personnalisée',
    default: false,
  },
  {
    id: 'custom_badge',
    label: '',
    description: "Activer l'affichage des badges",
    default: false,
  },
  {
    id: 'custom_background_color',
    label: '',
    description: 'Activer la couleur de fond personnalisée',
    default: false,
    deprecated: true,
  },
  {
    id: 'didomi_consent',
    label: '',
    description:
      'Permet de supprimer l’ancien bandeau des cookies et d’afficher la popin ou bandeau Didomi.',
    default: false,
  },
  {
    id: 'didomi_remove_tracking',
    label: '',
    description: 'Permet de supprimer les scripts de tracking',
    default: false,
    deprecated: true,
  },
  {
    id: 'display_breadcrumb',
    label: '',
    description: 'Afficher le breadcrumb',
    default: false,
  },
  {
    id: 'display_link_subscribe_later',
    label: '',
    description:
      'Afficher le lien "Je m\'abonne plus tard" en bas de la page d\'abonnement',
    default: true,
  },
  {
    id: 'display_next_season_episode',
    label: '',
    description: "Afficher l'épisode suivant dans le player",
    default: false,
  },
  {
    id: 'dynamic_filters',
    label: '',
    description: 'Activer la fonctionnalité de filtres dynamiques',
    default: false,
  },
  {
    id: 'force_landing',
    label: '',
    description: 'Redirige les utilisateurs sur la landing tous les 30 jours',
    default: false,
  },
  {
    id: 'environment_amazon',
    label: '',
    description: 'Activer le partenaire Amazon',
    default: false,
  },
  {
    id: 'environment_betv',
    label: '',
    description: 'Activer le partenaire BeTV(VOO)',
    default: false,
  },
  {
    id: 'environment_free',
    label: '',
    description: 'Activer le partenaire Free',
    default: false,
  },
  {
    id: 'environment_molotov',
    label: '',
    description: 'Activer le partenaire Molotov',
    default: false,
  },
  {
    id: 'export_users',
    label: 'Export utilisateurs',
    description: 'Activer l’export des utilisateurs',
    default: false,
  },
  {
    id: 'export_videos',
    label: "L'export vidéos",
    description: "Activer l'export des liens des fichiers vidéos du catalogue",
    default: false,
  },
  {
    id: 'freebox_videos',
    label: '',
    description: 'Permet de téléverser une vidéo pour Free.',
    default: false,
  },
  {
    id: 'fuzzy_search',
    label: '',
    description: 'Possibilité de recherche avec accent et casse.',
    default: true,
  },
  {
    id: 'geoblocking',
    label: '',
    description:
      'Permet de paramétrer les préférences de géoblocking de votre plateforme.',
    default: false,
  },
  {
    id: 'hide_front_link',
    label: '',
    description: 'Permet de masquer les url/liens vers le front',
    default: false,
  },
  {
    id: 'import_gar_esidoc',
    label: '',
    description: "Activer l'import des abonnements GAR et E-sidoc",
    default: false,
  },
  {
    id: 'import_users',
    label: 'Import utilisateurs',
    description: 'Activer l’import des utilisateurs',
    default: false,
  },
  {
    id: 'live',
    label: '',
    description: 'Paramétrer et créer des fiches live',
    default: false,
  },
  {
    id: 'missing_page_redirector',
    label: '',
    description: '',
    default: false,
  },
  {
    id: 'modal_add_block_v2',
    label: '',
    description: "Activer la nouvelle version de la modal d'ajout de bloc",
    default: false,
  },
  {
    id: 'multi_profile_light',
    label: '',
    description: '',
    default: false,
    deprecated: true,
  },
  {
    id: 'pagination',
    label: '',
    description: 'Activer la fonctionnalité de pagination',
    default: false,
  },
  {
    id: 'payment_reuse_card',
    label: '',
    description: 'Pouvoir réutiliser une carte CB',
    default: false,
  },
  {
    id: 'payment_sepa',
    label: 'Paiement SEPA',
    description: 'Activer le paiement via SEPA',
    default: false,
  },
  {
    id: 'person_v2',
    label: '',
    description: 'Activer la nouvelle version de la fiche personne',
    default: false,
  },
  {
    id: 'picture_xl_with_txt',
    label: '',
    description: 'DEPRECATED',
    default: true,
    deprecated: true,
  },
  {
    id: 'playback',
    label: '',
    description:
      'Enregistre la progression de la vidéo sur le player et les fiches programmes.',
    default: true,
  },
  {
    id: 'playback_block',
    label: '',
    description: 'Permet d’ajouter un bloc reprise de lecture dans l’Animation',
    default: false,
  },
  {
    id: 'playback_history',
    label: '',
    description:
      'Permet d’accéder à l’utilisateur d’accéder à la page Mon historique.',
    default: false,
  },
  {
    id: 'player_fullscreen',
    label: '',
    description: 'Activer le mode plein écran sur le player',
    default: false,
  },
  {
    id: 'playlist_hide_offline_programs',
    label: '',
    description:
      'Cacher les programmes hors ligne ajoutés dans la page Ma liste',
    default: false,
  },
  {
    id: 'piwik_pro_tracking',
    label: '',
    description: '',
    default: false,
  },
  {
    id: 'rating',
    label: '',
    description: '',
    default: false,
  },
  {
    id: 'recommendation_animation',
    label: '',
    description:
      'Permet d’ajouter un bloc de recommandation des programmes dans l’Animation.',
    default: false,
  },
  {
    id: 'recommendation_block',
    label: '',
    description: 'DEPRECATED',
    default: false,
    deprecated: false,
  },
  {
    id: 'recommandation_collection_specific',
    label: '',
    description:
      "Permet d’ajouter en début de recommendation d'un unitaire de collection, le collection correspondante.",
    default: false,
  },
  {
    id: 'recommendation_player',
    label: '',
    description:
      'Permet d’ajouter un bloc de recommandations dans le lecteur vidéo lorsque la vidéo se termine.',
    default: false,
  },
  {
    id: 'recommendation_program',
    label: '',
    description:
      'Permet d’ajouter une métadonnée de recommandations dans les fiches programmes.',
    default: false,
  },
  {
    id: 'rich_text_editor',
    label: '',
    description: "Ajoute plus de fonctionnalités pour l'édition de texte.",
    default: false,
  },
  {
    id: 'skip_intro_outro',
    label: '',
    description:
      'Permet d’ajouter des times codes pour passer le générique d’intro. (peut être lié à la recommandation player pour afficher la recommandation au moment du générique de fin)',
    default: false,
  },
  {
    id: 'tracking',
    label: '',
    description:
      "Permet d'activer les différents scripts externes de tracking sur le Front",
    default: true,
  },
  {
    id: 'tvod_checkout',
    label: '',
    description: 'Activer le parcours TVOD checkout',
    default: false,
  },
  {
    id: 'upload_image_on_save',
    label: '',
    description:
      "Permet de téléverser les images vers le S3 à l'enregistrement d'un élément",
    default: false,
  },
  {
    id: 'upload_to_s3',
    label: '',
    description:
      "Permet de téléverser les images vers le S3 avant l'enregistrement d'un élément",
    default: true,
  },
  {
    id: 'upsell',
    label: '',
    description: 'Possibilité pour les utilisateurs de changer d’abonnement',
    default: false,
  },
  {
    id: 'user_preferences_audio',
    label: '',
    description: 'Activer les préférences audio dans mon compte',
    default: true,
  },
  {
    id: 'user_preferences_subtitles',
    label: '',
    description: 'Activer les préférences sous-titres dans mon compte',
    default: true,
  },
  {
    id: 'vwo_smartcode_tracking',
    label: '',
    description: '',
    default: false,
  },
  {
    id: 'weglot',
    label: '',
    description: '',
    default: false,
  },
];

export default featuresConfig;
