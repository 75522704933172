import { RouterView } from 'vue-router';
import { h } from 'vue';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import PasswordReset from '@/views/PasswordReset.vue';

import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

const editSeasonBreadcrumb = [
  { name: 'home.nav.title', path: '/' },
  { name: 'catalogue.home.title', path: '/catalogue' },
  {
    name: ':parentMovieName',
    path: '/catalogue/:movieId',
    dynamic: true,
  },
  {
    name: ':movieName',
    path: '/catalogue/:movieId/saisons/:seasonId',
    dynamic: true,
  },
];

const editSeasonEpisodeBreadcrumb = [
  { name: 'home.nav.title', path: '/' },
  { name: 'catalogue.home.title', path: '/catalogue' },
  {
    name: ':parentMovieName',
    path: '/catalogue/:parentMovieId',
    dynamic: true,
  },
  {
    name: ':seasonName',
    path: '/catalogue/:parentMovieId/saisons/:seasonId',
    dynamic: true,
  },
  {
    name: ':movieName',
    path: '/catalogue/:movieId/episodes/:episodeId',
    dynamic: true,
  },
];

const editSerieEpisodeBreadcrumb = [
  { name: 'home.nav.title', path: '/' },
  { name: 'catalogue.home.title', path: '/catalogue' },
  {
    name: ':movieName',
    path: '/catalogue/:movieId',
    dynamic: true,
  },
  {
    name: ':episodeName',
    path: '/catalogue/:movieId/episodes/:episodeId',
    dynamic: true,
  },
];

export const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      theme: 'teal',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      theme: 'teal',
      isPublic: true,
      layout: 'no-navbar',
    },
  },
  {
    path: '/password/reset/:platformId/:token',
    name: 'passwordReset',
    component: PasswordReset,
    meta: {
      isPublic: true,
      layout: 'no-navbar',
    },
  },
  {
    path: '/catalogue',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'primary',
    },
    children: [
      {
        path: '',
        name: 'catalogue',
        component: () => import('@/views/Catalogue.vue'),
        meta: {
          theme: 'primary',
          breadcrumb: [
            { name: 'catalogue.title', path: '/' },
            { name: 'catalogue.home.title', path: '/catalogue' },
          ],
          permission: 'catalogue.access',
        },
      },
      {
        path: 'videos',
        name: 'videos',
        component: () => import('@/views/Videos.vue'),
        meta: {
          theme: 'primary',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'catalogue.home.title', path: '/catalogue' },
            { name: 'catalogue.pages.videos.title', path: '/catalogue/videos' },
          ],
          permission: 'catalogue.video.access',
        },
      },
      {
        path: 'videos/:videoId',
        name: 'editVideo',
        component: () => import('@/components/video/VideoEdit.vue'),
        meta: {
          theme: 'primary',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'catalogue.home.title', path: '/catalogue' },
            {
              name: 'catalogue.pages.videos.title',
              path: '/catalogue/videos',
            },
            {
              name: ':title',
              path: '/catalogue/videos/:videoId',
              color: 'teal',
              dynamic: true,
            },
          ],
          permission: 'catalogue.video.access',
        },
      },
      {
        path: 'participant',
        name: 'participant',
        component: () => import('@/views/Participants.vue'),
        meta: {
          theme: 'primary',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'catalogue.home.title', path: '/catalogue' },
            {
              name: 'catalogue.pages.casts.title',
              path: '/catalogue/participant',
            },
          ],
          permission: 'catalogue.participant.access',
        },
      },
      {
        path: 'participant/edit/:participantId',
        name: 'editParticipant',
        component: () => import('@/components/participant/ParticipantEdit.vue'),
        meta: {
          theme: 'primary',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'catalogue.home.title', path: '/catalogue', color: 'teal' },
            {
              name: 'catalogue.pages.casts.title',
              path: '/catalogue/participant',
              color: 'teal',
            },
            {
              name: ':title',
              path: '/catalogue/participant/edit/:participantId',
              color: 'teal',
              dynamic: true,
            },
          ],
          permission: 'catalogue.participant.access',
        },
      },
      {
        path: ':movieId',
        component: () => import('@/views/AddMovie.vue'),
        children: [
          {
            path: '',
            name: 'editMovieInfos',
            component: () => import('@/components/movie/add/MovieTabInfos.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                ],
              },
            },
          },
          {
            path: 'episodes',
            name: 'editMovieEpisode',
            component: () =>
              import('@/components/movie/add/MovieTabEpisodes.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.episodes',
                    path: '/catalogue/:movieId/episodes',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.episodes',
                    path: '/catalogue/:movieId/episodes',
                    dynamic: true,
                  },
                ],
              },
            },
          },
          {
            path: 'collection',
            name: 'editMovieCollection',
            component: () =>
              import('@/components/movie/add/MovieTabCollection.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.collection',
                    path: '/catalogue/:movieId/collection',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.collection',
                    path: '/catalogue/:movieId/collection',
                    dynamic: true,
                  },
                ],
              },
            },
          },
          {
            path: 'saisons',
            name: 'editMovieSeason',
            component: () =>
              import('@/components/movie/add/MovieTabEpisodes.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.seasons',
                    path: '/catalogue/:movieId/saisons',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/videos',
                    dynamic: true,
                  },
                ],
              },
            },
          },
          {
            path: 'images',
            name: 'editMovieImages',
            component: () =>
              import('@/components/movie/add/MovieTabImages.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'Images',
                    path: '/catalogue/:movieId/images',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.pictures',
                    path: '/catalogue/:movieId/images',
                    dynamic: true,
                  },
                ],
              },
            },
          },
          {
            path: 'videos',
            name: 'editMovieVideos',
            component: () =>
              import('@/components/movie/add/MovieTabVideos.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/videos',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/videos',
                    dynamic: true,
                  },
                ],
              },
            },
          },
          {
            path: 'programmation',
            name: 'editMovieProgrammation',
            component: () =>
              import('@/components/movie/add/MovieTabProgrammation.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.accessPrice',
                    path: '/catalogue/:movieId/programmation',
                    dynamic: true,
                  },
                ],
                season: [
                  { name: 'home.nav.title', path: '/' },
                  { name: 'catalogue.home.title', path: '/catalogue' },
                  {
                    name: ':parentMovieName',
                    path: '/catalogue/:parentMovieId',
                    dynamic: true,
                  },
                  {
                    name: ':movieName',
                    path: '/catalogue/:movieId',
                    dynamic: true,
                  },
                  {
                    name: 'catalogue.pages.program.nav.scheduling',
                    path: '/catalogue/:movieId/programmation',
                    dynamic: true,
                  },
                ],
              },
            },
          },
        ],
        meta: {
          theme: 'primary',
          permission: 'catalogue.index.access',
        },
      },
      {
        path: ':movieId/episodes/:episodeId',
        component: () => import('@/views/AddMovie.vue'),
        children: [
          {
            path: '',
            name: 'editEpisodeInfo',
            component: () => import('@/components/movie/add/MovieTabInfos.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: editSerieEpisodeBreadcrumb,
                season: editSeasonEpisodeBreadcrumb,
              },
            },
          },
          {
            path: 'videos',
            name: 'editEpisodeVideos',
            component: () =>
              import('@/components/movie/add/MovieTabVideos.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  ...editSerieEpisodeBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/episodes/:episodeId/videos',
                    dynamic: true,
                  },
                ],
                season: [
                  ...editSeasonEpisodeBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/episodes/:episodeId/videos',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },
          {
            path: 'images',
            name: 'editEpisodeImages',
            component: () =>
              import('@/components/movie/add/MovieTabImages.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  ...editSerieEpisodeBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.pictures',
                    path: '/catalogue/:movieId/episodes/:episodeId/images',
                    dynamic: true,
                  },
                ],
                season: [
                  ...editSeasonEpisodeBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/episodes/:episodeId/videos',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },

          {
            path: 'programmation',
            name: 'editEpisodeProgrammation',
            component: () =>
              import('@/components/movie/add/MovieTabProgrammation.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                default: [
                  ...editSerieEpisodeBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.scheduling',
                    path: '/catalogue/:movieId/episodes/:episodeId/programmation',
                    dynamic: true,
                  },
                ],
                season: [
                  ...editSeasonEpisodeBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.scheduling',
                    path: '/catalogue/:movieId/episodes/:episodeId/programmation',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },
        ],
        meta: {
          theme: 'primary',
        },
      },
      {
        path: ':movieId/saisons/:seasonId',
        component: () => import('@/views/AddMovie.vue'),
        children: [
          {
            path: '',
            name: 'editSeasonInfos',
            component: () => import('@/components/movie/add/MovieTabInfos.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                season: editSeasonBreadcrumb,
              },
            },
          },
          {
            path: 'episodes',
            name: 'editSeasonEpisodes',
            component: () =>
              import('@/components/movie/add/MovieTabEpisodes.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                season: [
                  ...editSeasonBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.episodes',
                    path: '/catalogue/:movieId/saisons/:seasonId/episodes',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },
          {
            path: 'videos',
            name: 'editSeasonVideos',
            component: () =>
              import('@/components/movie/add/MovieTabVideos.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                season: [
                  ...editSeasonBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.videos',
                    path: '/catalogue/:movieId/saisons/:seasonId/videos',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },
          {
            path: 'images',
            name: 'editSeasonImages',
            component: () =>
              import('@/components/movie/add/MovieTabImages.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                season: [
                  ...editSeasonBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.pictures',
                    path: '/catalogue/:movieId/saisons/:seasonId/images',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },
          {
            path: 'programmation',
            name: 'editSeasonProgrammation',
            component: () =>
              import('@/components/movie/add/MovieTabProgrammation.vue'),
            meta: {
              theme: 'primary',
              breadcrumb: {
                season: [
                  ...editSeasonBreadcrumb,
                  {
                    name: 'catalogue.pages.program.nav.scheduling',
                    path: '/catalogue/:movieId/saisons/:seasonId/programmation',
                    dynamic: true,
                  },
                ],
                permission: 'catalogue.video.access',
              },
            },
          },
        ],
        meta: {
          theme: 'primary',
        },
      },
    ],
  },
  {
    path: '/animation',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'secondary',
      breadcrumb: [
        { name: 'home.nav.title', path: '/' },
        { name: 'animation.home.title', path: '/animation' },
      ],
      permission: 'animation.access',
    },
    children: [
      {
        name: 'animation',
        path: '',
        component: () => import('@/views/Animation.vue'),
      },
      {
        path: 'pages',
        name: 'animationPages',
        component: () => import('@/views/AnimationPages.vue'),
        meta: {
          theme: 'secondary',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'animation.home.title', path: '/animation' },
            { name: 'animation.pages.pages.title', path: '/animation/pages' },
          ],
        },
      },
      {
        path: ':templateType/:templateName/:templateId',
        name: 'animationPage',
        component: () => import('@/views/AnimationPage.vue'),
        meta: {
          theme: 'secondary',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'animation.home.title', path: '/animation' },
            { name: 'animation.pages.pages.title', path: '/animation/Pages' },
            {
              name: ':title',
              path: '/animation/:templateType/:templateName/:templateId',
              dynamic: true,
              color: 'teal',
            },
          ],
        },
      },
    ],
  },
  // Deprecated with multihome [JJ]
  {
    path: '/animation/home',
    name: 'animationHome',
    component: () => import('@/views/AnimationPage.vue'),
    meta: {
      theme: 'secondary',
      breadcrumb: [
        { name: 'home.nav.title', path: '/' },
        { name: 'animation.home.title', path: '/animation' },
        { name: 'animation.pages.homepage.title', path: '/animation/home' },
      ],
      permission: 'animation.home.access',
    },
  },
  {
    path: '/selections',
    name: 'catalogueSelections',
    component: () => import('@/views/CatalogueSelections.vue'),
    meta: {
      theme: 'secondary',
      breadcrumb: [
        { name: 'home.nav.title', path: '/' },
        { name: 'animation.home.title', path: '/animation' },
        { name: 'animation.pages.selections.title', path: '/selections' },
      ],
      permission: 'animation.selection.access',
    },
  },
  {
    path: '/animation/selections',
    name: 'animationSelections',
    component: () => import('@/views/AnimationSelections.vue'),
    meta: {
      theme: 'secondary',
      breadcrumb: [
        { name: 'home.nav.title', path: '/' },
        { name: 'animation.home.title', path: '/animation' },
        { name: 'animation.pages.selections.title', path: '/selections' },
        {
          name: 'animation.pages.selectionsAnimation.title',
          path: '/animation/selections',
        },
      ],
      permission: 'animation.selection.access',
    },
  },

  {
    path: '/selection/:selectionId',
    component: () => import('@/views/AddSelection.vue'),
    meta: {
      theme: 'secondary',
      permission: 'animation.selection.access',
    },
    children: [
      {
        path: '',
        name: 'editSelectionInfos',
        component: () =>
          import('@/components/selection/add/SelectionTabInfos.vue'),
        meta: {
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'animation.pages.selections.title', path: '/selections' },
            {
              name: ':selectionName',
              path: '/selection/:selectionId',
              dynamic: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/utilisateurs',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'red',
    },
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/Users.vue'),
        meta: {
          theme: 'red',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'users.home.title', path: '/utilisateurs' },
          ],
          permission: 'users.index.access',
        },
      },
      {
        path: ':userId',
        component: () => import('@/views/User.vue'),
        meta: {
          theme: 'red',
          permission: 'users.index.access',
        },
        children: [
          {
            path: '',
            name: 'user',
            component: () => import('@/components/user/UserTabProfile.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'users.home.title', path: '/utilisateurs' },
                {
                  name: ':userEmail',
                  path: '/utilisateurs/:userId',
                  dynamic: true,
                },
              ],
            },
          },
          {
            path: 'videos',
            name: 'userVideos',
            component: () => import('@/components/user/UserTabVideos.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'users.home.title', path: '/utilisateurs' },
                {
                  name: ':userEmail',
                  path: '/utilisateurs/:userId',
                  dynamic: true,
                },
                {
                  name: 'users.pages.user.nav.videosBreadcrumb',
                  path: '/utilisateurs/:userId/videos',
                  dynamic: true,
                },
              ],
            },
          },
          {
            path: 'transactions',
            name: 'userTransactions',
            component: () =>
              import('@/components/user/UserTabTransactions.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'users.home.title', path: '/utilisateurs' },
                {
                  name: ':userEmail',
                  path: '/utilisateurs/:userId',
                  dynamic: true,
                },
                {
                  name: 'users.pages.user.nav.transactionsBreadcrumb',
                  path: '/utilisateurs/:userId/transactions',
                  dynamic: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/marketing',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'yellow',
    },
    children: [
      {
        path: '',
        name: 'marketing',
        component: () => import('@/views/Marketing.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
          ],
          permission: 'marketing.access',
        },
      },
      {
        path: 'groupes-plans',
        name: 'group-plans',
        component: () => import('./views/GroupPlans.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            {
              name: 'marketing.pages.plans.title',
              path: '/marketing/groupes-plans',
            },
          ],
          permission: 'marketing.offer.access',
        },
      },
      {
        path: 'groupes-plans/rang',
        name: 'plans-page',
        component: () => import('./views/PlansPage.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            {
              name: 'marketing.pages.plans.title',
              path: '/marketing/groupes-plans',
            },
            {
              name: 'marketing.pages.plansPage.title',
              path: '/marketing/groupes-plans/rang',
            },
          ],
          permission: 'marketing.offer.access',
        },
      },
      {
        path: 'groupes-plans/:groupPlanId',
        component: () => import('./views/GroupPlan.vue'),
        children: [
          {
            path: '',
            name: 'group-plan-settings',
            component: () =>
              import('./components/group_plan/tab/GroupPlanTabSettings'),
            meta: {
              theme: 'yellow',
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                {
                  name: 'marketing.pages.plans.title',
                  path: '/marketing/groupes-plans',
                },
                {
                  name: ':groupPlanName',
                  path: '/marketing/groupes-plans/:groupPlanId',
                },
              ],
            },
          },
          {
            path: 'list',
            name: 'group-plan-plans',
            component: () =>
              import('./components/group_plan/tab/GroupPlanTabList'),
            meta: {
              theme: 'yellow',
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                {
                  name: 'marketing.pages.plans.title',
                  path: '/marketing/groupes-plans',
                },
                {
                  name: ':groupPlanName',
                  path: '/marketing/groupes-plans/:groupPlanId',
                },
                {
                  name: 'marketing.pages.groupPlan.tabs.list.title',
                  path: '/marketing/groupes-plans/:groupPlanId/list',
                },
              ],
            },
          },
        ],
        meta: {
          theme: 'yellow',
          permission: 'marketing.offer.access',
        },
      },
      {
        path: 'groupes-plans/:groupPlanId/list/:planId',
        name: 'plan',
        component: () => import('./views/Plan.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            {
              name: 'marketing.pages.plans.title',
              path: '/marketing/groupes-plans',
            },
            {
              name: ':groupPlanName',
              path: '/marketing/groupes-plans/:groupPlanId',
            },
            {
              name: 'marketing.pages.groupPlan.tabs.settings.title',
              path: '/marketing/groupes-plans/:groupPlanId/list',
            },
            {
              name: ':planName',
              path: '/marketing/groupes-plans/:groupPlanId/list/:planId',
            },
          ],
          permission: 'marketing.offer.access',
        },
      },
      {
        path: 'discount-campaigns',
        name: 'discount-campaigns',
        component: () => import('@/views/DiscountCampaigns.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            {
              name: 'marketing.pages.discount.title',
              path: '/marketing/discount-campaigns',
            },
          ],
          permission: 'marketing.promo.access',
        },
      },
      {
        path: 'discount-campaigns/:discountCampaignId',
        name: 'discount-campaign',
        component: () => import('@/views/DiscountCampaign.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            {
              name: 'marketing.pages.discount.title',
              path: '/marketing/discount-campaigns',
            },
            {
              name: ':title',
              path: '/marketing/discount-campaigns/:discountCampaignId',
              dynamic: true,
            },
          ],
          permission: 'marketing.promo.access',
        },
      },
      {
        path: 'ads',
        component: () => import('./views/Avod.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            { name: 'marketing.pages.ads.title', path: '/marketing' },
            {
              name: 'marketing.pages.ads.nav.topBanner',
              path: '/marketing/ads/top-banner',
            },
          ],
          permission: 'marketing.access',
        },
        children: [
          {
            path: 'top-banner',
            name: 'adsTopBanner',
            component: () => import('./components/avod/AvodTabTopBanner.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                { name: 'marketing.pages.ads.title', path: '/marketing' },
                {
                  name: 'marketing.pages.ads.nav.topBanner',
                  path: '/marketing/ads/top-banner',
                },
              ],
            },
          },
          {
            path: 'interstitial',
            name: 'adsInterstitial',
            component: () =>
              import('./components/avod/AvodTabInterstitial.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                { name: 'marketing.pages.ads.title', path: '/marketing' },
                {
                  name: 'marketing.pages.ads.nav.interstitial',
                  path: '/marketing/ads/interstitial',
                },
              ],
            },
          },
          {
            path: 'banner',
            name: 'adsBanner',
            component: () => import('./components/avod/AvodTabBanner.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                { name: 'marketing.pages.ads.title', path: '/marketing' },
                {
                  name: 'marketing.pages.ads.nav.banner',
                  path: '/marketing/ads/banner',
                },
              ],
            },
          },
          {
            path: 'block',
            name: 'adsBlock',
            component: () => import('./components/avod/AvodTabPave.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                { name: 'marketing.pages.ads.title', path: '/marketing' },
                {
                  name: 'marketing.pages.ads.nav.block',
                  path: '/marketing/ads/block',
                },
              ],
            },
          },
          {
            path: 'pre-roll',
            name: 'adsPreRoll',
            component: () => import('./components/avod/AvodTabPreroll.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.title', path: '/' },
                { name: 'marketing.home.title', path: '/marketing' },
                { name: 'marketing.pages.ads.title', path: '/marketing' },
                {
                  name: 'marketing.pages.ads.nav.preRoll',
                  path: '/marketing/ads/pre-roll',
                },
              ],
            },
          },
        ],
      },
      {
        path: 'educational',
        name: 'educational',
        component: () => import('./views/Educational.vue'),
        meta: {
          theme: 'yellow',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'marketing.home.title', path: '/marketing' },
            {
              name: 'marketing.home.nav.educational.title',
              path: '/marketing/educational',
            },
          ],
          permission: 'marketing.offer.access', // marketing.gar-esidoc.access
        },
      },
    ],
  },
  {
    path: '/statistiques',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'purple',
      breadcrumb: [
        { name: 'home.nav.title', path: '/' },
        { name: 'kpis.dashboard.title', path: '/statistiques' }
      ],
      permission: 'stats.access',
    },
    children: [
      {
        path: '',
        name: 'statistiques',
        component: () => import('@/views/Statistics.vue'),
      },
      {
        path: 'utilisateurs',
        name: 'statisticsUsers',
        component: () => import('@/views/StatisticsUsers.vue'),
        meta: {
          theme: 'purple',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'kpis.dashboard.title', path: '/statistiques' },
            {
              name: 'kpis.page.users.title',
              path: '/statistiques/utilisateurs',
            },
          ],
        },
      },
      {
        path: 'visionnages',
        name: 'statisticsViews',
        component: () => import('@/views/StatisticsViews.vue'),
        meta: {
          theme: 'purple',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'kpis.dashboard.title', path: '/statistiques' },
            {
              name: 'kpis.page.views.title',
              path: '/statistiques/visionnages',
            },
          ],
        },
      },
    ]
  },
  {
    path: '/parametres',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'green',
    },
    children: [
      {
        path: '',
        name: 'parameters',
        component: () => import('@/views/Parameters.vue'),
        meta: {
          theme: 'green',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'settings.home.title', path: '/parametres' },
          ],
          permission: 'settings.access',
        },
      },
      {
        path: 'site',
        component: () => import('@/views/ParametersSite.vue'),
        meta: {
          theme: 'green',
          permission: 'settings.appearance.access',
        },
        children: [
          {
            path: '',
            name: 'parametersSite',
            component: () =>
              import(
                '@/components/parameters/site/ParametersSiteTabGlobal.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
              ],
            },
          },
          {
            path: 'header-footer',
            name: 'parametersSiteTabHeaderFooter',
            component: () =>
              import(
                './components/parameters/site/ParametersSiteTabHeaderFooter.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.header',
                  path: '/parametres/site/header-footer',
                },
              ],
            },
          },
          {
            path: 'blocs-animation',
            name: 'parametersSiteTabAnimationBlocks',
            component: () =>
              import(
                './components/parameters/site/ParametersSiteTabAnimationBlocks.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.animation',
                  path: '/parametres/site/blocs-animation',
                },
              ],
            },
          },
          {
            path: 'pages-contenu',
            name: 'parametersSiteTabProgramPages',
            component: () =>
              import(
                './components/parameters/site/ParametersSiteTabProgramPages.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.content',
                  path: '/parametres/site/pages-contenu',
                },
              ],
            },
          },
          {
            path: 'pages-contenu',
            name: 'parametersSiteTabContentPages',
            component: () =>
              import(
                './components/parameters/site/ParametersSiteTabProgramPages.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.content',
                  path: '/parametres/site/pages-contenu',
                },
              ],
            },
          },
          {
            path: 'pages-serie',
            name: 'parametersSiteTabSeriePages',
            component: () =>
              import(
                './components/parameters/site/ParametersSiteTabSeriePages.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.serie',
                  path: '/parametres/site/pages-serie',
                },
              ],
            },
          },
          {
            path: 'page-catalogue',
            name: 'parametersSiteTabCataloguePage',
            component: () =>
              import(
                './components/parameters/site/ParametersSiteTabCataloguePage.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.catalogue',
                  path: '/parametres/site/page-catalogue',
                },
              ],
            },
          },
          {
            path: 'pages-live',
            name: 'parametersSiteTabLivePages',
            component: () =>
              import(
                '@/components/parameters/site/ParametersSiteTabLivePages.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.live',
                  path: '/parametres/site/pages-live',
                },
              ],
            },
          },
          {
            path: 'pages-collection',
            name: 'parametersSiteTabCollectionPages',
            component: () =>
              import(
                '@/components/parameters/site/ParametersSiteTabCollectionPages.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.appearance.title',
                  path: '/parametres/site',
                },
                {
                  name: 'settings.pages.appearance.nav.collection',
                  path: '/parametres/site/pages-collection',
                },
              ],
            },
          },
        ],
      },
      {
        path: 'profil',
        component: () => import('@/views/ParametersProfile.vue'),
        meta: {
          theme: 'green',
          permission: 'settings.profile.access',
        },
        children: [
          {
            path: '/parametres/profil',
            name: 'ParametersProfileTabInformations',
            component: () =>
              import(
                './components/parameters/profile/ParametersProfileTabInformations.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.profile.title',
                  path: '/parametres/profil',
                },
              ],
            },
          },
        ],
      },
      {
        path: 'meta-donnees',
        name: 'parametersMetadatas',
        component: () => import('@/views/ParametersMetadatas.vue'),
        meta: {
          theme: 'green',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'settings.home.title', path: '/parametres' },
            {
              name: 'settings.pages.metadatas.title',
              path: '/parametres/meta-donnees',
            },
          ],
          permission: 'settings.metadata.access',
        },
      },
      {
        path: 'meta-donnees/:metadataDetailId',
        component: () => import('@/views/AddMetadataDetail.vue'),
        meta: {
          theme: 'green',
          permission: 'settings.metadata.access',
        },
        children: [
          {
            path: '',
            name: 'editMetadataDetailInfos',
            component: () =>
              import('@/components/metadata/tab/MetadataDetailTabSettings.vue'),
            meta: {
              theme: 'green',
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.metadatas.title',
                  path: '/parametres/meta-donnees',
                },
                {
                  name: ':metadataDetailTitle',
                  path: '/parametres/meta-donnees/:metadataDetailId',
                  dynamic: true,
                },
              ],
            },
          },
          {
            path: 'list',
            name: 'editMetadataDetailList',
            component: () =>
              import('@/components/metadata/tab/MetadataDetailTabList.vue'),
            meta: {
              theme: 'green',
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'settings.home.title', path: '/parametres' },
                {
                  name: 'settings.pages.metadatas.title',
                  path: '/parametres/meta-donnees',
                },
                {
                  name: ':metadataDetailTitle',
                  path: '/parametres/meta-donnees/:metadataDetailId',
                  dynamic: true,
                },
                {
                  name: 'settings.pages.metadata.nav.edit',
                  path: '/parametres/meta-donnees/:metadataDetailId/list',
                  dynamic: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: 'meta-donnees/:metadataDetailId/option/:optionId',
        name: 'editMetadataDetailOption',
        component: () => import('@/views/MetadataDetailOption.vue'),
        meta: {
          theme: 'green',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'settings.home.title', path: '/parametres' },
            {
              name: 'settings.pages.metadatas.title',
              path: '/parametres/meta-donnees',
            },
            {
              name: ':metadataDetailTitle',
              path: '/parametres/meta-donnees/:metadataDetailId',
              dynamic: true,
            },
            {
              name: 'settings.pages.metadata.nav.edit',
              path: '/parametres/meta-donnees/:metadataDetailId/list',
              dynamic: true,
            },
            {
              name: ':optionTitle',
              path: '/parametres/meta-donnees/:metadataDetailId/option/:optionId',
              dynamic: true,
            },
          ],
          permission: 'settings.metadata.access',
        },
      },
    ],
  },
  {
    path: '/tech',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'teal',
      permission: 'tech.index.access',
    },
    children: [
      {
        path: '',
        name: 'tech',
        component: () => import('@/views/Tech.vue'),
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
          ],
        },
      },
      {
        path: 'site',
        component: () => import('@/views/TechSite.vue'),
        meta: {
          theme: 'teal',
        },
        children: [
          {
            path: '/tech/site/social',
            name: 'TechSiteTabSocial',
            component: () =>
              import('@/components/tech/site/TechSiteTabSocial.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Social',
                  path: '/tech/site/social',
                },
              ],
            },
          },
          {
            path: 'user',
            name: 'TechSiteTabUser',
            component: () =>
              import('@/components/tech/site/TechSiteTabUser.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'User',
                  path: '/tech/site/user',
                },
              ],
            },
          },
          {
            path: 'buttons',
            name: 'techSiteTabButtons',
            component: () =>
              import('@/components/tech/site/TechSiteTabButtons.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Buttons',
                  path: '/tech/site/buttons',
                },
              ],
            },
          },
          {
            path: 'devices',
            name: 'techSiteTabDevices',
            component: () =>
              import('@/components/tech/site/TechSiteTabDevices.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Devices',
                  path: '/tech/site/devices',
                },
              ],
            },
          },
          {
            path: 'seo',
            name: 'techSiteTabSeo',
            component: () =>
              import('@/components/tech/site/TechSiteTabSeo.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'SEO',
                  path: '/tech/site/seo',
                },
              ],
            },
          },
          {
            path: 'others',
            name: 'techSiteTabOthers',
            component: () =>
              import('@/components/tech/site/TechSiteTabOthers.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Others',
                  path: '/tech/site/others',
                },
              ],
            },
          },
          {
            path: 'pictures',
            name: 'techSiteTabPictures',
            component: () =>
              import('@/components/tech/site/TechSiteTabPictures.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Pictures',
                  path: '/tech/site/pictures',
                },
              ],
            },
          },
          {
            path: 'colors',
            name: 'techSiteTabColors',
            component: () =>
              import('@/components/tech/site/TechSiteTabColors.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Colors',
                  path: '/tech/site/colors',
                },
              ],
            },
          },
          {
            path: 'tracking',
            name: 'techSiteTabTracking',
            component: () =>
              import('@/components/tech/site/TechSiteTabTracking.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Tracking',
                  path: '/tech/site/tracking',
                },
              ],
            },
          },
          {
            path: 'search',
            name: 'techSiteTabSearch',
            component: () =>
              import('@/components/tech/site/TechSiteTabSearch.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Search',
                  path: '/tech/site/search',
                },
              ],
            },
          },
          {
            path: 'recommendations',
            name: 'techSiteTabRecommendations',
            component: () =>
              import('@/components/tech/site/TechSiteTabRecommendations.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Recommendations',
                  path: '/tech/site/recommendations',
                },
              ],
            },
          },
          {
            path: 'design',
            name: 'techSiteTabDesign',
            component: () =>
              import('@/components/tech/site/TechSiteTabDesign.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Design',
                  path: '/tech/site/design',
                },
              ],
            },
          },
          {
            path: 'all',
            name: 'techSiteTabAll',
            component: () =>
              import('@/components/tech/site/TechSiteTabAll.vue'),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'All',
                  path: '/tech/site/all',
                },
              ],
            },
          },
        ],
      },
      {
        path: 'media',
        component: () => import('@/views/TechMedia.vue'),
        meta: {
          theme: 'teal',
        },
        children: [
          {
            path: '/tech/media/playbackConfiguration',
            name: 'TechMediaTabPlaybackConfiguration',
            component: () =>
              import(
                '@/components/tech/media/TechMediaTabPlaybackConfiguration.vue'
              ),
            meta: {
              breadcrumb: [
                { name: 'home.nav.title', path: '/' },
                { name: 'tech.title', path: '/tech' },
                {
                  name: 'Playback configuration',
                  path: '/tech/media/playbackConfiguration',
                },
              ],
            },
          },
        ],
      },
      {
        path: 'legal',
        component: () => import('@/views/TechLegal.vue'),
        name: 'listLegalPages',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Textes', path: '/tech/legal' },
          ],
          permission: 'tech.index.legal',
        },
        children: [],
      },
      {
        path: 'legal/:legalId',
        component: () => import('@/views/AddLegal.vue'),
        name: 'editLegalInfos',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Textes', path: '/tech/legal' },
            {
              name: ':legalName',
              path: '/tech/legal/:legalId',
              dynamic: true,
            },
          ],
          permission: 'tech.index.legal',
        },
        children: [],
      },
      {
        path: 'email',
        component: () => import('@/views/TechEmail.vue'),
        name: 'listEmailPages',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Emails', path: '/tech/email' },
          ],
          permission: 'tech.index.email',
        },
      },
      {
        path: 'email/:emailId',
        component: () => import('@/views/AddEmail.vue'),
        name: 'editEmailInfos',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Emails', path: '/tech/email' },
            {
              name: ':emailName',
              path: '/tech/email/:emailId',
              dynamic: true,
            },
          ],
          permission: 'tech.index.email',
        },
      },
      {
        path: 'ftp-user',
        component: () => import('@/views/TechFtpUser.vue'),
        name: 'listFtpUserPages',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Comptes Ftp', path: '/tech/ftp-user' },
          ],
          permission: 'tech.index.ftp',
        },
        children: [],
      },
      {
        path: 'ftp-user/:ftpUserId',
        component: () => import('@/views/AddFtpUser.vue'),
        name: 'editFtpUserInfos',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Accès Ftp', path: '/tech/ftp-user' },
            {
              name: ':ftpUserTitle',
              path: '/tech/ftp-user/:ftpUserId',
              dynamic: true,
            },
          ],
          permission: 'tech.index.ftp',
        },
        children: [],
      },
      {
        path: 'newsletter',
        component: () => import('@/views/TechNewsletter.vue'),
        name: 'techNewsletter',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Newsletter', path: '/tech/TechNewsletter' },
          ],
          permission: 'tech.index.newsletter',
        },
      },
      {
        path: 'badges',
        component: () => import('@/views/TechBadges.vue'),
        name: 'techBadges',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Badges', path: '/tech/badges' },
          ],
          permission: 'tech.index.badge',
        },
      },
      {
        path: 'badge/:badgeType',
        component: () => import('./views/TechBadge.vue'),
        name: 'techBadge',
        meta: {
          theme: 'black',
          breadcrumb: [
            { name: 'home.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Badges', path: '/tech/badges' },
            { name: 'Badge', path: '/tech/badge' },
          ],
          permission: 'tech.index.badge',
        },
      },
      {
        path: 'font/family/:id',
        component: () => import('./views/TechFonts.vue'),
        name: 'TechFontFamily',
        meta: {
          theme: 'black',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Font families', path: '/tech/font/family' },
            { name: ':title', path: '/tech/font/family/:id' },
          ],
          permission: 'tech.index.font',
        },
      },
      {
        path: 'font/family',
        component: () => import('./views/TechFontFamilies.vue'),
        name: 'TechFontFamilies',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'tech.title', path: '/tech' },
            { name: 'Font families', path: '/tech/font/family' },
          ],
          permission: 'tech.index.font',
        },
      },
      {
        path: 'pictures-advanced',
        name: 'techTabPicturesAdvanced',
        component: () =>
          import('@/components/tech/site/TechPictureAdvanced.vue'),
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: "Page d'accueil", path: '/' },
            { name: 'Tech', path: '/tech' },
            {
              name: 'Configuration avancée des images',
              path: '/tech/pictures-advanced',
            },
          ],
          permission: 'tech.index.picture',
        },
      },
      {
        path: 'icons',
        name: 'techTabIcons',
        component: () =>
          import('@/components/tech/site/TechIcons.vue'),
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: "Page d'accueil", path: '/' },
            { name: 'Tech', path: '/tech' },
            {
              name: 'Icons',
              path: '/tech/icons',
            },
          ],
          permission: 'tech.beta',
        },
      },
    ],
  },
  {
    path: '/advanced-settings',
    component: { render: () => h(RouterView) },
    meta: {
      theme: 'red',
      permission: 'advanced-settings.features.access',
    },
    children: [
      {
        path: '',
        name: 'advanced settings',
        component: () => import('@/views/AdvancedSettings.vue'),
        meta: {
          theme: 'red',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'advancedSettings.home.title', path: '/advanced-settings' },
          ],
        },
      },
      {
        path: 'features',
        component: () => import('@/views/AdvancedSettingsFeatures.vue'),
        name: 'advancedSettingsFeatures',
        meta: {
          theme: 'red',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'advancedSettings.home.title', path: '/advanced-settings' },
            { name: 'Features', path: '/advanced-settings/features' },
          ],
        },
      },
    ],
  },
  {
    path: '/admins',
    component: () => import('@/views/Admins.vue'),
    meta: {
      theme: 'teal',
    },
    children: [
      {
        path: 'access',
        name: 'adminsAccess',
        component: () => import('@/components/admins/AdminsAccess.vue'),
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'admin.pages.admins.all', path: '/admins/access' },
          ],
          permission: 'admins.access',
        },
      },
      {
        path: 'profile',
        component: () => import('@/components/admins/AdminProfile.vue'),
        name: 'adminProfile',
        meta: {
          theme: 'teal',
          breadcrumb: [
            { name: 'home.nav.title', path: '/' },
            { name: 'admin.pages.admins.profile', path: '/admins/profile' },
          ],
        },
      },
    ],
  },
  {
    path: '/403',
    component: () => import('@/components/error/Error403.vue'),
    name: 'error403',
  },
  // The default route, when none of the above matches:
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    alias: '/404',
    component: () => import('@/components/error/404.vue'),
  },
];

let router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (from.path !== to.path) {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (store.getters['auth/isAuthenticated'] && to.meta?.permission) {
    console.debug('can router', to.meta.permission);
    if (to.meta?.permission && !store.getters['auth/can'](to.meta.permission)) {
      store.dispatch('auth/setError', 403);
      next();
      return;
    }
  }
  store.commit('setRoute', to);
  store.dispatch('auth/setError', 0);

  if (!to.matched.some((record) => record.meta.isPublic)) {
    if (store.getters['auth/isAuthenticated']) {
      next();
      return;
    }
    next('/login');
  } else if (to.name === 'login') {
    if (store.getters['auth/isAuthenticated']) {
      next({ name: 'home' });
      return;
    }
    next();
  } else {
    next();
  }
});

router.afterEach((to) => {
  localStorage.setItem('LS_ROUTE_KEY', to.name);
});

export default router;
